import moment from 'moment-timezone';
import * as serverTime from '../enums/serverTime';
import { Modal, message } from 'antd';
import { NINJAVAN_METAFIELDS_TIME } from '../enums/StandardDelivery';
import { COURIERS_LIST } from '../enums/DeliveryConstants';
import { YYYY_MM_DDTHH_MM } from '../enums/dateFormats';
import { BOOK_ANY } from '../enums/AppConstants';
import GCash from '../dao/Gcash';
import { MODULE_PATH } from '../../constants/Module';
import { CONVENIENCE_FEE } from '../enums/DeliveryConstants';

export const isEmpty = args => {
  return (
    args === undefined ||
    args === 0 ||
    args === null ||
    args === '' ||
    args.length === 0 ||
    (typeof args === 'object' && Object.keys(args).length === 0)
  );
};

export const numFloat = num => {
  return (Math.round(Number(num) * 100) / 100).toFixed(2);
};

export const handleReturnMyAccounts = navigate => {
  return navigate(MODULE_PATH.MY_ACCOUNTS.MAIN);
};

export const capitalizeFirstChar = text => {
  if (isEmpty(text)) return '';
  const arr = text.toLowerCase().split(' ');

  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }

  const str = arr.join(' ');
  return str;
};

export const cleanAdress = (province, city, barangay, code = null) => {
  let stringArr = [];

  if (!isEmpty(province)) {
    stringArr.push(capitalizeFirstChar(province));
  }

  if (!isEmpty(city)) {
    stringArr.push(capitalizeFirstChar(city));
  }

  if (!isEmpty(barangay)) {
    stringArr.push(capitalizeFirstChar(barangay));
  }

  if (!isEmpty(code)) {
    stringArr.push(capitalizeFirstChar(code));
  }

  return stringArr.join(', ');
};

export const setTimeInterval = start => {
  const timeModulus = moment(start).minute() % 15;
  const subs = timeModulus <= 7 ? 15 : 30;
  const remainder = subs - timeModulus;

  const dateTime = moment(start).add(remainder, 'minutes').set({ second: 0, millisecond: 0 });
  return moment(dateTime).valueOf();
};

export const getServerTime = async () => {
  let time = moment().valueOf();

  await serverTime.getServerTime(serverTime => {
    const momentServerTime = serverTime => moment.tz(serverTime, 'Asia/Manila');
    const newTime = momentServerTime(serverTime);

    time = moment(newTime).valueOf();
  });

  return time;
};

export const phoneFormat = input => {
  if (!input) return;
  return input.replace(/(\d{4})(\d{3})(\d{5})/, '$1-$2-$3');
};

export const getFlooredFixed = (v, d) => {
  return (Math.floor(v * Math.pow(10, d)) / Math.pow(10, d)).toFixed(d);
};

export const formatText = string => {
  if (!string) return;
  var str = string?.toLowerCase();
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatBills = x => {
  return numFloat(x).toLocaleString('en-US');
};

export const phraseFormat = mySentence => {
  const words = mySentence.split(' ');

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }

  return words.join(' ');
};

export const copyToClipBoard = text => {
  const tempInput = document.createElement('textarea');

  tempInput.value = text;
  document.body.appendChild(tempInput);
  tempInput.select();
  document.execCommand('copy');
  document.body.removeChild(tempInput);
  message.success('Copied to Clipboard!', 3.0);
};

export const generateOrderId = () => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomChars = '';

  for (let i = 0; i < 7; i++) {
    randomChars += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  const date = moment().format('YY');
  const refNo = `${date}-${randomChars}`;

  return refNo;
};

export const formatAmount = amount => {
  const AmountwithDecimal = (amount / 100).toFixed(2);
  const [value, decimal] = AmountwithDecimal.split('.');

  if (value.length >= 4) {
    const withComma = Number(value).toLocaleString();
    return `${withComma}.${decimal}`;
  }

  return AmountwithDecimal;
};

export const formatThousandAmount = num => {
  const [strNumber, decimal] = num.toString().split('.');

  let result = '';
  let count = 0;

  if (strNumber.length >= 4) {
    for (let i = strNumber.length - 1; i >= 0; i--) {
      count++;
      result = strNumber.charAt(i) + result;
      if (count % 3 === 0 && i !== 0) {
        result = ',' + result;
      }
    }

    return `${result}.${decimal}`;
  }

  return num;
};

export const sortList = list => {
  return list.slice().sort((a, b) => b.createdAt - a.createdAt);
};

export const formatOrderListMongo = orderListMongo => {
  return (
    orderListMongo.length > 0 &&
    orderListMongo.map(obj => {
      const { senderDetails } = obj.pickUp;
      const { recipientDetails } = obj.dropOff;
      const { courier, rate, vehicleType } = obj.courier;

      const isBookAny = courier === BOOK_ANY;
      return {
        createdAt: obj.createdAt,
        refNo: obj.refNo,
        customerName: senderDetails.fullName,
        pickupAddress: senderDetails.address,
        contactNumber: senderDetails.contactNo,
        emailAddress: senderDetails.emailAddress,
        pickupCity: senderDetails.city,
        province: senderDetails.province,
        barangay: senderDetails.barangay,
        courierName: courier
          ? `${courier.charAt(0).toUpperCase()}${courier.slice(1).toLowerCase()}`
          : '',
        finalShippingFee: rate + (isBookAny ? CONVENIENCE_FEE.ADVANCE : CONVENIENCE_FEE.REGULAR),
        status: 'CANCELLED',
        metaFields: { serviceType: vehicleType },
        courierId: courier || '',
        partnerId: courier || '',
        gcash: { ...obj.gcash, cancelledReason: 'Error encountered' },
        meta: JSON.stringify({
          customerName: recipientDetails.fullName,
          deliveryAddress: recipientDetails.address,
          contactNumber: recipientDetails.contactNo,
          emailAddress: recipientDetails.emailAddress,
          deliveryDateTime: recipientDetails.deliveryDateTime,
          deliveryCity: recipientDetails.city,
          province: recipientDetails.province,
          barangay: recipientDetails.barangay,
          declaredValue: recipientDetails.itemPrice || 0,
          dimensionText: obj?.vehicleType?.description.dimensionText.replace('in', ''),
        }),
      };
    })
  );
};

// export const formatFailedBooking = bookings => {
//   return (
//     bookings.length > 0 &&
//     bookings.map(obj => {
//       const {pickupDetails} = obj

//       return {
//         createdAt: obj?.createdAt || '',
//         refNo: obj.refNo,
//         customerName: pickupDetails.senderName,
//         pickupAddress: pickupDetails.pickupAddress,
//         contactNumber: pickupDetails.contactNumber,
//         emailAddress: pickupDetails.emailAddress || '',
//         pickupCity: pickupDetails.pickupCity,
//         province: pickupDetails.province,
//         barangay: pickupDetails.barangay || '',
//         courierName: courier
//           ? `${courier.charAt(0).toUpperCase()}${courier.slice(1).toLowerCase()}`
//           : '',
//         finalShippingFee: rate + 5,
//         status: 'CANCELLED',
//         metaFields: { serviceType: vehicleType },
//         courierId: courier || '',
//         partnerId: courier || '',
//         gcash: { ...obj.gcash, cancelledReason: 'Error encountered' },
//         meta: JSON.stringify({
//           customerName: recipientDetails.fullName,
//           deliveryAddress: recipientDetails.address,
//           contactNumber: recipientDetails.contactNo,
//           emailAddress: recipientDetails.emailAddress,
//           deliveryDateTime: recipientDetails.deliveryDateTime,
//           deliveryCity: recipientDetails.city,
//           province: recipientDetails.province,
//           barangay: recipientDetails.barangay,
//           declaredValue: recipientDetails.itemPrice || 0,
//           dimensionText: obj?.vehicleType?.description.dimensionText.replace('in', ''),
//         }),
//       };
//     })
//   );
// };

export const isEmptyObjectFields = obj => {
  for (let field in obj) {
    if (!obj[field] && obj[field] !== 0) {
      return true;
    }
  }
  return false;
};

export const delay = interval => new Promise(resolve => setTimeout(resolve, interval));

export const filterCourierPartner = (partners, couriers) => {
  const onDemandPartners = partners
    .filter(partner => partner.serviceType === 'onDemand')
    .map(onDemandPartner => onDemandPartner.id);
  const filteredCourierPartner = couriers.filter(courier => onDemandPartners.includes(courier));

  return filteredCourierPartner;
};

export const capitalizeName = name => {
  const words = name?.toLowerCase().split(' ');
  const capitalizedWords = words?.map(word => word.charAt(0).toUpperCase() + word.slice(1));
  const capitalizedName = capitalizedWords?.join(' ');
  return capitalizedName;
};

export const formatPhoneNumber = phoneNumber => {
  if (!phoneNumber) return;
  const digits = phoneNumber.replace(/\D/g, '');
  const match = digits.match(/^(?:\+?63|0)?(9\d{2})(\d{3})(\d{4})$/);
  return match ? `+63 ${match[1]} ${match[2]} ${match[3]}` : phoneNumber;
};

export const validatePhoneNumber = phoneNumber => {
  // Regular expression patterns for Philippine phone numbers
  // const mobileNumberPattern = /^(09|\+639)\d{9}$/; // Matches mobile numbers starting with 09 or +639, followed by 9 digits
  // const landlineNumberPattern = /^(02|\+632)\d{7}$/; // Matches landline numbers starting with 02 or +632, followed by 7 digits
  const mobileNumberPattern = /^(9|\+639)\d{9}$/;
  if (!mobileNumberPattern.test(phoneNumber)) {
    return false;
  }
  return true;
};

export const validateEmail = email => {
  // Regular expression pattern for validating email addresses
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(email);
};

export const paymentStatus = order => {
  const { gcash, status } = order;
  if (gcash?.refund?.success) {
    return 'Refunded';
  }
  if (!gcash?.refund?.success && status === 'CANCELLED') {
    return 'For refund';
  }
  return 'Paid';
};

export const isValidPhoneNumber = value => {
  if (isEmpty(value)) {
    return {
      isValid: false,
    };
  }
  // remove spaces
  const trimValue = value.replace(/ /g, '');
  // prefix checker
  const isValidPrefix =
    (trimValue.startsWith('0') && trimValue.startsWith('09')) || trimValue.startsWith('9');
  // remove zero
  const cleanValue =
    isValidPrefix && trimValue.startsWith('0') ? trimValue.substring(1) : trimValue;

  return {
    isValid: isValidPrefix && cleanValue.length === 10 && !isNaN(Number(cleanValue)),
    value: cleanValue,
  };
};

export const matchNinjavanMetafieldsTime = (arrayTime, inputTime) => {
  let matchedItem = null;
  for (const timeRange of arrayTime) {
    const [startTime, endTime] = timeRange.split('-');
    if (inputTime >= startTime && inputTime <= endTime) {
      matchedItem = timeRange;
      break;
    }
  }
  return !matchedItem ? '09:00-22:00' : matchedItem;
};

export const formatUnderscoredText = input => {
  return input
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

export const createOrderPayload = (order, userInfo) => {
  const { pickUp, dropOff, courier, vehicleType, product } = order;

  const dimension = (vehicleType?.description?.dimensionText.match(/\d+/g) || []).map(n =>
    parseInt(n)
  );
  const weight = (vehicleType?.description?.weightText.match(/\d+/g) || []).map(n => parseInt(n));

  const selectedCourier = String(courier.courier).toUpperCase();
  const isBookAny = courier.courier === BOOK_ANY;
  let booking = {
    refNo: order.refNo,
    createdBy: order.userId,
    gcash: order.gcash,
    isAutoReattempt: false,
    isBroadcastOndemand: isBookAny,
    pickupDetails: {
      senderName: pickUp.senderDetails.fullName,
      contactNumber: formatContactNumber(pickUp.senderDetails.contactNo),
      emailAddress: pickUp.senderDetails.emailAddress || userInfo.currentUser.email,
      pickupDateTime: moment(order.pickupDateTime).format(YYYY_MM_DDTHH_MM),
      pickupAddress: pickUp.address,
      pickupCity: pickUp.city || pickUp.senderDetails.city,
      province: pickUp.province || pickUp.senderDetails.province,
      barangay: pickUp.senderDetails.barangay,
      lat: pickUp.latitude,
      long: pickUp.longitude,
      remarks: pickUp?.notesToRider?.notes || '',
    },
    deliveryDetails: {
      recipientName: dropOff.recipientDetails.fullName,
      contactNumber: formatContactNumber(dropOff.recipientDetails.contactNo),
      emailAddress: dropOff.recipientDetails.emailAddress || userInfo.currentUser.email,
      deliveryDateTime: moment(order.pickupDateTime).endOf('day').format(YYYY_MM_DDTHH_MM),
      deliveryAddress: dropOff.address,
      deliveryCity: dropOff.city || dropOff.recipientDetails.city,
      province: dropOff.province || dropOff.recipientDetails.province,
      productSize: product?.value ? product?.value[0] : '',
      barangay: dropOff.recipientDetails.barangay,
      declaredValue: Number(order.itemPrice) || 0,
      vehicleType: vehicleType?.vehicleType.toUpperCase() || '',
      length: dimension[0],
      width: dimension[1],
      height: dimension[2],
      weight: weight[0],
      depth: dimension[2],
      lat: dropOff.latitude,
      long: dropOff.longitude,
      itemDescription: order.itemDescription,
      metaFields: {},
      tipAmount: order.driversTip || 0,
      remarks: dropOff?.notesToRider?.notes || '',
    },
  };

  if (isBookAny) {
    delete booking.deliveryDetails.tipAmount;
  }

  // GRAB
  if (selectedCourier === 'GRAB') {
    booking.deliveryDetails.metaFields = {
      grabVehicleType: String(courier.vehicleType).toUpperCase(),
    };
  }

  // LALAMOVE
  if (selectedCourier === 'LALAMOVE') {
    booking.deliveryDetails.metaFields = {
      serviceType: String(courier.vehicleType).toUpperCase(),
    };
  }

  // MRSPEEDY
  // if (selectedCourier === 'MRSPEEDY') {
  //   booking.deliveryDetails.metaFields = {
  //     mrSpeedyVehicle: String(courier.vehicleType).toUpperCase(),
  //     motoboxRequired: String(courier.vehicleType === 8),
  //   };
  // }

  // TRANSPORTIFY
  // if (selectedCourier === COURIERS_LIST[3]) {
  //   booking.deliveryDetails.metaFields = {
  //     vehicle: String(courier.vehicleType).toLowerCase(),
  //     length: String(dimension[0]),
  //     width: String(dimension[1]),
  //     height: String(dimension[2]),
  //     weight: String(weight[0]),
  //     quantity: '1',
  //   };
  // }
  //QUADX
  if (selectedCourier === 'QUADX') {
    booking.deliveryDetails.metaFields = {
      delivery_postal_code: String(dropOff.recipientDetails.postalCode) || '0',
      pickup_postal_code: String(pickUp.senderDetails.postalCode) || '0',
    };
  }

  //NINJAVAN
  const inputPickupTime = moment(order.pickupDateTime).format('HH:mm');
  const inputDeliveryTime = moment(order.pickupDateTime).endOf('day').format('HH:mm');
  const pickupTime = matchNinjavanMetafieldsTime(NINJAVAN_METAFIELDS_TIME, inputPickupTime);
  const deliveryTime = matchNinjavanMetafieldsTime(NINJAVAN_METAFIELDS_TIME, inputDeliveryTime);

  if (selectedCourier === 'NINJAVAN') {
    booking.deliveryDetails.metaFields = {
      deliveryTimeSlot: deliveryTime,
      pickupTimeSlot: pickupTime,
    };
  }
  return {
    orderPayload: booking,
    courierPayload: selectedCourier,
  };
};
export const formatContactNumber = contact => {
  return contact[0] !== '0' ? `0${contact}` : contact;
};
export const showConfirmationModal = url => {
  Modal.confirm({
    className: 'link-out',
    okText: 'Proceed',
    cancelText: 'Cancel',
    content: 'You are being redirected outside GCash. Would you like to proceed?',
    onOk: async () => {
      await GCash.linkOut(url);
    },
    onCancel: () => {
      // console.log('Cancel');
    },
  });
};

export const capitalizeLastChar = text => {
  const lastIndex = text.length - 1;

  if (lastIndex >= 0) {
    text = `${text.slice(0, lastIndex)}${text[lastIndex].toUpperCase()}}`;
  }
  return text;
};

export const calculateCashbackDeduction = (shippingFee, cashback) => {
  if (cashback > shippingFee) {
    return shippingFee;
  }

  if (cashback < shippingFee && cashback > 0) {
    return cashback;
  }

  return 0;
};

export const calculateFinalShippingfee = (shippingFee, cashback) => {
  if (cashback > shippingFee) {
    return 0;
  }

  if (shippingFee > cashback) {
    console.log('fall here');
    return shippingFee - cashback;
  }

  return 0;
};

export const calculateTotalBalance = cashbackTransactions => {
  const earnedCashback = cashbackTransactions.filter(data => data.cashbackType === 'Earned');
  const usedCashback = cashbackTransactions.filter(data => data.cashbackType === 'Used');

  const totalEarnedCashback = earnedCashback.reduce((curr, obj) => curr + obj.amount, 0);
  const totalUsedCashback = usedCashback.reduce((curr, obj) => curr + obj.amount, 0);

  if (Number(totalUsedCashback) > Number(totalEarnedCashback)) {
    return 0;
  }

  return Number(totalEarnedCashback) - Number(totalUsedCashback);
};

export const Ellips = val => {
  let words = val.trim().split(' ');
  let firstWord = words[0];
  if (firstWord.length > 6 || words.length === 1) {
    return firstWord;
  } else {
    return words[1].length > 6 ? words[0] : words[0] + ' ' + words[1];
  }
};
