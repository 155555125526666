import React from 'react';
import { useNavigate } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';

import { setSelectedAddress } from '../redux/addressBook/actions';
import { setFormView } from '../redux/home/actions';
import { MODULE_PATH, PAGE } from '../constants/Module';
import { setStandardInitialState } from '../redux/standard/actions';
import { setOnDemandInitialState } from '../redux/onDemand/actions';

const confirm = Modal.confirm;

const StickyHeader = ({
  onPrevNavigate, // { path: '/', state: {} }
  title,
  onNextNavigate, // { path: '/', state: {}, label: 'Add New', imgPath: '', path },
  setSelectedAddress,
  setFormView,
  callback,
}) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const view = useSelector(state => state.homeReducer.view);
  const isStandardAndOndemandView =
    view === MODULE_PATH.STANDARD.MAIN || view === MODULE_PATH.ON_DEMAND.MAIN;

  const handlePrevNavigate = () => {
    const obj = onPrevNavigate?.state ? { state: { ...onPrevNavigate?.state } } : {};
    if (isStandardAndOndemandView && onPrevNavigate?.path === '/') {
      confirm({
        className: 'discard-order-modal',
        title: 'Discard Order',
        okText: 'Stay on this page',
        cancelText: 'Leave page',
        content: (
          <>
            Are you sure you want to leave this page?
            <br />
          </>
        ),
        onOk: () => {
          // console.log('Cancel');
        },
        onCancel: () => {
          navigate(onPrevNavigate?.path, { ...obj });
        },
      });
      return;
    }
    if (!onPrevNavigate?.path) return;
    onPrevNavigate?.setAddressValue && setSelectedAddress({ ...onPrevNavigate?.setAddressValue });
    if (onPrevNavigate?.path === '/') {
      setFormView(PAGE.HOME);
    }
    if (callback) {
      callback()
    }

    return navigate(onPrevNavigate?.path, { ...obj });
  };

  const handleNextNavigate = () => {
    if (!onNextNavigate?.path) return;
    const obj = onNextNavigate?.state ? { state: { ...onNextNavigate?.state } } : {};
    return navigate(onNextNavigate?.path, { ...obj });
  };

  const ArrowLeftButton = () => (
    <Button className="back-button" icon={<ArrowLeftOutlined />} onClick={handlePrevNavigate} />
  );

  const additionalClass = title.replace(' ', '-').toLowerCase();
  const onNextNavigateClass = onNextNavigate ? '' : 'no__next__nav';
  return (
    <div
      className={`sticky-header-container ${
        onPrevNavigate ? '' : 'hidden'
      } ${additionalClass} ${onNextNavigateClass}`}
    >
      {onPrevNavigate && <ArrowLeftButton />}
      <div className={`last-two-columns ${onNextNavigateClass}`}>
        <span className="label lbl-base-light">{title}</span>
      </div>
      {onNextNavigate && (
        <div
          className={`last-two-column next-navigate ${onNextNavigate?.className}`}
          onClick={handleNextNavigate}
        >
          <img src={onNextNavigate?.imgPath} alt="img path" />
          <span className='lbl-base-light'>{onNextNavigate?.label}</span>
        </div>
      )}
    </div>
  );
};

const matchDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setSelectedAddress,
      setFormView,
    },
    dispatch
  );

export default connect(null, matchDispatchToProps)(StickyHeader);
