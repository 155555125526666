import { Tabs } from 'antd';
import React, { useEffect, useState, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { useLocation, useNavigate } from 'react-router';
import OrdersCard from '../../../../../shared/components/OrdersCard';
import { connect, useDispatch, useSelector } from 'react-redux';
import { setFormView } from '../../../../../shared/redux/home/actions';
import { MODULE_PATH, PAGE } from '../../../../../shared/constants/Module';

import {
  fetchCancelledOrders,
  fetchDoneOrders,
  fetchForRefundOrders,
  fetchOrdersFromMongo,
  fetchPendingOrders,
} from '../../../../../shared/redux/home/actions';
import { formatOrderListMongo, sortList } from '../../../../../shared/utils/helpers/purefunctions';
import { ICON } from '../../../../../shared/utils/enums/AppIcons';
import IconSearch from '../../../../../shared/assets/svg/home-booking/icon_search.svg';
import StickyHeader from '../../../../../shared/components/StickyHeader';
import {
  CANCELLED_BY_BROADCAST_ONDEMAND,
  HAS_ASSIGNED_RIDER,
} from '../../../../../shared/utils/enums/AppConstants';
import { setAppViewAll } from '../../../../../shared/redux/app/actions';

const ViewAllOrders = ({ setFormView }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [filteredOrders, setFilteredOrders] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [tab, setTab] = useState('1');
  const inputRef = useRef();

  const fetchedAll = useSelector(state => state.homeReducer.fetchAll);
  const user = useSelector(state => state.usersReducer.currentUser);
  const appAccess = useSelector(state => state.appAccessReducer);
  const { orderList, completedOrderList, orderListMongo, forRefundOrders, cancelledOrders } =
    useSelector(state => state.homeReducer);

  const isFromOrderPlaced = location?.state?.isFromOrderPlaced;
  const formattedOrderListMongo =
    orderListMongo.length > 0 ? formatOrderListMongo(orderListMongo) : [];

  const allCancelledOrders = [...formattedOrderListMongo, ...forRefundOrders, ...cancelledOrders];

  const arrOfRefNo = [];
  const filteredOrderList = orderList.filter(order => {
    if (!arrOfRefNo.includes(order.refNo)) {
      arrOfRefNo.push(order.refNo);
      return true;
    }
  });

  const arrOfCancelledRefNo = [];
  const filteredCancelledOrders = allCancelledOrders.filter(order => {
    if (!arrOfCancelledRefNo.includes(order.refNo)) {
      arrOfCancelledRefNo.push(order.refNo);
      const isBookAnyAutoCancelled =
        order?.lastUpdateRemarks?.includes(CANCELLED_BY_BROADCAST_ONDEMAND) &&
        order?.lastUpdateRemarks?.includes(HAS_ASSIGNED_RIDER);
      return !isBookAnyAutoCancelled;
    }
  });

  const allOrders = [...filteredOrderList, ...completedOrderList, ...filteredCancelledOrders];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    const actionFunctions = [
      fetchPendingOrders,
      fetchForRefundOrders,
      fetchCancelledOrders,
      fetchOrdersFromMongo,
      fetchDoneOrders,
    ];
    actionFunctions.forEach(actionFunction => {
      dispatch(actionFunction(user.id, 'false'));
    });
  }, [user.id, dispatch]);

  const filterOrders = (orders, searchValue) => {
    return orders.filter(order => {
      const deliveryDetails = JSON.parse(order.meta);
      const isMatch =
        order.refNo.includes(searchValue) ||
        order.customerName.includes(searchValue) ||
        deliveryDetails.customerName.includes(searchValue) ||
        order.customerName.toLowerCase().includes(searchValue.toLowerCase()) ||
        deliveryDetails.customerName.toLowerCase().includes(searchValue.toLowerCase());
      return isMatch;
    });
  };

  const handleChange = e => {
    const { value } = e.target;
    const filtered = filterOrders(allOrders, value);

    if (!value) {
      setTab('1');
      return setIsSearching(false);
    }
    setFilteredOrders(filtered);
    setIsSearching(true);

    if (filtered.length) {
      setTab(
        filtered[0]?.status === 'DONE' ? '2' : filtered[0]?.status === 'CANCELLED' ? '3' : '1'
      );
    }
  };

  const tempOngoingOrders = isSearching ? filteredOrders : filteredOrderList;
  const tempCompleted = isSearching ? filteredOrders : completedOrderList;
  const tempCancelledOrders = isSearching ? filteredOrders : filteredCancelledOrders;

  const NoOrder = ({ status }) => (
    <span className="loading__orders" style={{ color: '#7d838f', fontSize: '12px' }}>
      <div className="no-orders-icon">
        <img src={ICON.NO_ORDER_ALL} title="No Orders Yet" alt="no order yet" />
      </div>
      <span className="header">{`There are no ${status} orders!`}</span>
      <span className="no-orders-txt">
        Book your <span style={{ color: '#E94E75' }}>new order</span> now!
      </span>
    </span>
  );

  const renderOrders = (orders, status) => {
    const filterLatestOrder = sortList(orders).slice(0, 9);
    if (!fetchedAll && !isFromOrderPlaced) {
      return (
        <span className="loading__orders" style={{ color: '#7d838f', fontSize: '12px' }}>
          Loading . . .
        </span>
      );
    }
    return !orders.length ? (
      <NoOrder status={status} />
    ) : (
      <OrdersCard isOngoing={status === 'ongoing'} orders={filterLatestOrder} />
    );
  };

  const items = [
    {
      key: '1',
      label: 'Ongoing',
      children: renderOrders(tempOngoingOrders, 'ongoing'),
    },
    {
      key: '2',
      label: 'Completed',
      children: renderOrders(tempCompleted, 'completed'),
    },
    {
      key: '3',
      label: 'Cancelled',
      children: renderOrders(tempCancelledOrders, 'cancelled'),
    },
  ];

  const navigateToHome = () => {
    setFormView(PAGE.HOME);
    navigate(MODULE_PATH.HOME);
  };

  const handleEligibility = () => {
    if (appAccess?.viewAll) {
      dispatch(setAppViewAll(false));
    }
  };

  const prevNavigate = {
    label: 'My Orders',
    imgPath: '',
    path: location?.state?.path || MODULE_PATH.HOME,
  };
  return (
    <div className="view__all__orders" onClick={() => handleEligibility()}>
      <StickyHeader title="My Orders" onPrevNavigate={prevNavigate} />
      <div className="search-location">
        <img src={IconSearch} alt="search" />
        <input className='lbl-base-light' placeholder="Search order..." onChange={handleChange} ref={inputRef} />
      </div>
      {/* <BackTop /> */}

      <Tabs
        defaultActiveKey="1"
        activeKey={tab}
        items={items}
        onChange={e => setTab(e)}
        indicator={origin => origin + 50}
      />
    </div>
  );
};

const matchDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setFormView,
    },
    dispatch
  );
};

export default connect(null, matchDispatchToProps)(ViewAllOrders);
