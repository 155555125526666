import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import { message } from 'antd';

import StickyHeader from '../../../../shared/components/StickyHeader';
import FormInput from '../../../../shared/components/FormInput';
import SubmitButton from '../../../../shared/components/SubmitButton';
import ProvinceCityBarangay from '../../../../shared/components/ProvinceCityBarangay';

import { MODULE_PATH } from '../../../../shared/constants/Module';
import { REF, INITIAL_STATE } from '../../../../shared/constants/AddressBook';

import { setSelectedAddress } from '../../../../shared/redux/addressBook/actions';

import {
  isEmpty,
  isValidPhoneNumber,
  validateEmail,
  cleanAdress,
} from '../../../../shared/utils/helpers/purefunctions';
import Localities from '../../../../shared/utils/enums/Philippines.json';
import { ICON } from '../../../../shared/utils/enums/AppIcons';
import { setDropOff, setPickUp, setCourier } from '../../../../shared/redux/standard/actions';
import { setPrevRoute } from '../../../../shared/redux/home/actions';

import FooterBtn from '../../../../shared/components/FooterBtn';

const SenderDetails = ({ user, setSelectedAddress, selectedAddress }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [addressDetails, setAddressDetails] = useState(INITIAL_STATE);
  const [inputFocus, setInputFocus] = useState(null);
  const [showAddressDrawer, setShowAddressDrawer] = useState(false);
  const [provinceOptions, setProvinceOptions] = useState(null);
  const [isClicked, setIsClicked] = useState(false);

  const { pointLocation, pickUp, dropOff } = useSelector(state => state.standardReducer);

  const {
    unitFloorHouse,
    bldgStreet,
    provinceCityBarangay,
    province,
    city,
    barangay,
    postalCode,
    landmark,
    contactNo,
    fullName,
    emailAddress,
    notesToRider,
  } = addressDetails;

  const _unitFloorHouse = !isEmpty(unitFloorHouse);
  const _bldgStreet = !isEmpty(bldgStreet);
  const _provinceCityBarangay = !isEmpty(provinceCityBarangay);
  const _postalCode = !isEmpty(postalCode);
  const _landmark = !isEmpty(landmark);

  const _contactNo = !isEmpty(contactNo);
  const _fullName = !isEmpty(fullName);
  const _emailAddress = !isEmpty(emailAddress);
  const _notesToRider = !isEmpty(notesToRider);

  const hasAddress =
    _unitFloorHouse || _bldgStreet || _provinceCityBarangay || _postalCode || _landmark;
  const hasContact = _contactNo || _fullName || _emailAddress || _notesToRider;

  useEffect(() => {
    const PSGC = Localities;
    const province = [];
    Object.keys(PSGC).forEach(item => {
      const list = PSGC[item].province_list;
      Object.keys(list).forEach(name => {
        province.push({
          name,
          child: list[name].municipality_list,
        });
      });
    });

    setProvinceOptions({ types: 'province', data: province });
    dispatch(setPrevRoute(pointLocation));
  }, []);

  useEffect(() => {
    if (!isEmpty(selectedAddress)) {
      setAddressDetails({
        ...selectedAddress,
        addressType: selectedAddress?.addressType || '',
        unitFloorHouse: selectedAddress?.unitFloorHouse || '',
        bldgStreet: selectedAddress?.bldgStreet || '',
        provinceCityBarangay: cleanAdress(
          selectedAddress?.province || null,
          selectedAddress?.city || null,
          selectedAddress?.barangay || null
        ),
        postalCode: selectedAddress?.postalCode || '',
        landmark: selectedAddress?.landmark || '',
        contactNo: formatNumber(selectedAddress?.contactNo || ''),
        fullName: selectedAddress?.fullName || '',
        emailAddress: selectedAddress?.emailAddress || '',
        notesToRider: selectedAddress?.notesToRider || '',
      });
    }

    if (!isEmpty(pickUp.senderDetails) && pointLocation === 'PickUp') {
      return setAddressDetails({
        ...selectedAddress,
        province: pickUp.province,
        city: pickUp.city,
        barangay: pickUp?.barangay || pickUp.senderDetails?.barangay,
        provinceCityBarangay: `${pickUp.province}, ${pickUp.city}, ${
          pickUp?.barangay || pickUp.senderDetails?.barangay
        }`,
        contactNo: pickUp.senderDetails.contactNo,
        fullName: pickUp.senderDetails.fullName,
        unitFloorHouse: pickUp.senderDetails.unitFloorHouse,
        bldgStreet: pickUp.senderDetails.bldgStreet,
        postalCode: pickUp.senderDetails.postalCode,
        emailAddress: pickUp.senderDetails.emailAddress || '',
      });
    }

    if (!isEmpty(dropOff.recipientDetails) && pointLocation === 'DropOff') {
      return setAddressDetails({
        ...selectedAddress,
        province: dropOff.province,
        city: dropOff.city,
        barangay: dropOff?.barangay || dropOff.recipientDetails?.barangay,
        provinceCityBarangay: `${dropOff.province}, ${dropOff.city}, ${
          dropOff?.barangay || dropOff.recipientDetails?.barangay
        }`,
        contactNo: dropOff.recipientDetails.contactNo,
        fullName: dropOff.recipientDetails.fullName,
        unitFloorHouse: dropOff.recipientDetails.unitFloorHouse,
        bldgStreet: dropOff.recipientDetails.bldgStreet,
        postalCode: dropOff.recipientDetails.postalCode,
        emailAddress: dropOff.recipientDetails.emailAddress || '',
      });
    }
  }, [selectedAddress]);

  useEffect(() => {
    if (!isEmpty(provinceOptions)) {
      let __city = [];
      if (!isEmpty(selectedAddress?.province)) {
        const data = provinceOptions.data.filter(e =>
          e.name.toLowerCase().includes((selectedAddress?.province).toLowerCase())
        );

        if (data.length) {
          const childData = data[0].child;
          Object.keys(childData).forEach(name => {
            const child = childData[name].barangay_list;
            __city.push({
              name,
              child,
            });
          });
        }
      }

      let _brgy = [];
      if (!isEmpty(__city)) {
        const brgy = __city.filter(e =>
          e.name.toLowerCase().includes((selectedAddress?.city).toLowerCase())
        );
        if (brgy.length) {
          _brgy = brgy[0].child;
        }
      }

      let brgyExist = !isEmpty(selectedAddress?.barangay);
      if (!isEmpty(_brgy) && brgyExist) {
        brgyExist = _brgy.includes((selectedAddress?.barangay).toUpperCase());
      }

      if (!isEmpty(addressDetails) && pointLocation === 'PickUp') {
        return setAddressDetails({
          ...addressDetails,
          province: addressDetails.province,
          city: addressDetails.city,
          barangay: addressDetails.barangay,
          provinceCityBarangay: !addressDetails.province
            ? null
            : `${addressDetails.province}, ${addressDetails.city}, ${addressDetails.barangay}`,
        });
      }

      if (!isEmpty(addressDetails) && pointLocation === 'DropOff') {
        return setAddressDetails({
          ...addressDetails,
          province: addressDetails.province,
          city: addressDetails.city,
          barangay: addressDetails.barangay,
          provinceCityBarangay: !addressDetails.province
            ? null
            : `${addressDetails.province}, ${addressDetails.city}, ${addressDetails.barangay}`,
        });
      }

      if (isEmpty(__city) || isEmpty(_brgy) || !brgyExist) {
        setAddressDetails({
          ...selectedAddress,
          province: '',
          city: '',
          barangay: '',
          provinceCityBarangay: '',
        });
      }
    }
  }, [provinceOptions, selectedAddress]);

  useEffect(() => {
    const timeOutId = setTimeout(() => fieldValidations(), 1000);
    return () => clearTimeout(timeOutId);
  }, [addressDetails]);

  const setProvinceCityBrgy = provinceCityBrgy => {
    let address = {
      ...addressDetails,
      province: provinceCityBrgy.province,
      city: provinceCityBrgy.city,
      barangay: provinceCityBrgy.barangay,
      provinceCityBarangay: cleanAdress(
        provinceCityBrgy?.province,
        provinceCityBrgy?.city,
        provinceCityBrgy?.barangay
      ),
    };

    if (
      provinceCityBrgy.province.toLowerCase() !== (addressDetails?.province).toLowerCase() ||
      provinceCityBrgy.city.toLowerCase() !== (addressDetails?.city).toLowerCase() ||
      provinceCityBrgy?.barangay.toLowerCase() !== (addressDetails?.barangay).toLowerCase()
    ) {
      address = {
        ...address,
        latitude: 0,
        longitude: 0,
      };
    }
    setAddressDetails(address);
    setSelectedAddress(address);
  };

  const showNotif = debounce(async (type, msg) => {
    message[type](msg);
  }, 500);

  const clearAddress = () => {
    return setAddressDetails({
      ...addressDetails,
      unitFloorHouse: '',
      bldgStreet: '',
      provinceCityBarangay: '',
      province: '',
      city: '',
      barangay: '',
      postalCode: '',
      landmark: '',
    });
  };

  const clearContact = () => {
    return setAddressDetails({
      ...addressDetails,
      contactNo: '',
      fullName: '',
      emailAddress: '',
      notesToRider: '',
    });
  };

  const areAllFieldsFilled = obj => {
    return Object.keys(obj).every(key => Boolean(obj[key]));
  };

  const handleClearField = field => {
    setIsClicked(true);
    let address = {};
    if (field === 'provinceCityBarangay') {
      address = { ...address, province: '', city: '', barangay: '' };
    }

    setSelectedAddress({ ...addressDetails, [field]: '', ...address });
    setAddressDetails({ ...addressDetails, [field]: '', ...address });
    setIsClicked(false);
  };

  const handleInputFocus = fieldName => {
    !isClicked && setInputFocus(fieldName);
  };

  const handleRemoveInputFocus = () => {
    if (!isClicked && inputFocus === 'contactNo' && !isEmpty(addressDetails?.contactNo)) {
      let newValue = addressDetails.contactNo.replace(/ /g, '');
      const validatePhone = isValidPhoneNumber(addressDetails.contactNo);
      if (validatePhone.isValid) {
        const input = validatePhone.value;
        var zip = input.substring(0, 3);
        var middle = input.substring(3, 6);
        var last = input.substring(6, 10);
        newValue = `${zip} ${middle} ${last}`;
      }
      setAddressDetails({ ...addressDetails, contactNo: newValue });
      setSelectedAddress({ ...addressDetails, contactNo: newValue });
    }

    setInputFocus(null);
  };

  const formatNumber = num => {
    const validatePhone = isValidPhoneNumber(num);
    if (validatePhone.isValid) {
      const input = validatePhone.value;
      var zip = input.substring(0, 3);
      var middle = input.substring(3, 6);
      var last = input.substring(6, 10);
      return `${zip} ${middle} ${last}`;
    }
    return num;
  };

  const handleAddressChange = (field, value) => {
    const fieldLimit = ['unitFloorHouse', 'bldgStreet', 'notesToRider'];
    if (fieldLimit.includes(field)) {
      if (value.length > 120) return;
    }

    setAddressDetails({ ...addressDetails, [field]: value });
  };

  const fieldValidations = () => {
    if (inputFocus === 'contactNo') {
      const validatePhone = isValidPhoneNumber(addressDetails.contactNo);
      if (!validatePhone.isValid) {
        showNotif('error', 'Please enter a valid contact number.');
      }
    }

    if (inputFocus === 'emailAddress') {
      if (!validateEmail(addressDetails.emailAddress)) {
        showNotif('error', 'Invalid email format. Please try again.');
      }
    }
  };

  const hasInvalidValue = inputString => {
    if (typeof inputString !== 'string' || !inputString.includes(',')) {
      return true;
    }

    return inputString.split(',').some(value => {
      const trimmedValue = value.trim();
      return trimmedValue === 'undefined' || trimmedValue === 'null' || trimmedValue === '';
    });
  };

  const checkForm = () => {
    const pcb = hasInvalidValue(provinceCityBarangay) ? '' : provinceCityBarangay;

    const requiredFields = {
      unitFloorHouse,
      pcb,
      postalCode,
      fullName,
      barangay,
      contactNo,
    };
    const validatePhone = addressDetails?.contactNo
      ? isValidPhoneNumber(addressDetails.contactNo).isValid
      : true;
    const validateEmailAddress = addressDetails?.emailAddress
      ? validateEmail(addressDetails.emailAddress)
      : true;

    return areAllFieldsFilled(requiredFields) && validatePhone && validateEmailAddress;
  };

  const isProceed = checkForm();
  const pushAddress = () => {
    setIsClicked(true);
    const completeAddress = `${addressDetails.unitFloorHouse}, ${addressDetails.bldgStreet}, ${addressDetails.provinceCityBarangay}`;
    if (pointLocation === 'PickUp') {
      dispatch(setSelectedAddress(addressDetails));
      dispatch(
        setPickUp({
          city: addressDetails.city,
          province: addressDetails.province,
          address: completeAddress,
          senderDetails: {
            fullName: addressDetails.fullName,
            unitFloorHouse: addressDetails.unitFloorHouse,
            contactNo: addressDetails.contactNo,
            address: completeAddress,
            city: addressDetails.city,
            province: addressDetails.province,
            barangay: addressDetails.barangay,
            postalCode: addressDetails.postalCode,
            bldgStreet: addressDetails.bldgStreet,
            emailAddress: addressDetails?.emailAddress,
          },
        })
      );
      if (completeAddress !== pickUp?.senderDetails?.address) {
        dispatch(setCourier({}));
      }
    }

    if (pointLocation === 'DropOff') {
      dispatch(setSelectedAddress(addressDetails));
      dispatch(
        setDropOff({
          city: addressDetails.city,
          province: addressDetails.province,
          address: completeAddress,
          recipientDetails: {
            fullName: addressDetails.fullName,
            unitFloorHouse: addressDetails.unitFloorHouse,
            contactNo: addressDetails.contactNo,
            address: completeAddress,
            city: addressDetails.city,
            province: addressDetails.province,
            barangay: addressDetails.barangay,
            postalCode: addressDetails.postalCode,
            bldgStreet: addressDetails.bldgStreet,
            emailAddress: addressDetails?.emailAddress,
          },
        })
      );
      if (completeAddress !== dropOff?.recipientDetails?.address) {
        dispatch(setCourier({}));
      }
    }

    const HOME_PATH = pointLocation === 'DropOff' ? MODULE_PATH.HOME : MODULE_PATH.HOME;
    console.log('home path:', HOME_PATH);
    return isProceed ? navigate(HOME_PATH, { state: { isClicked: true } }) : null;
  };

  useEffect(() => {
    if (isEmpty(dropOff.recipientDetails) && pointLocation === 'DropOff') {
      setAddressDetails(INITIAL_STATE);
    }
  }, []);

  useEffect(() => {
    if (!MODULE_PATH.STANDARD.MAIN && !barangay && selectedAddress.barangay) {
      setAddressDetails(prevState => ({
        ...prevState,
        barangay: selectedAddress.barangay,
      }));
    }
  }, [barangay]);

  // sticker header props
  const prevNavigate = {
    path: MODULE_PATH.HOME,
    state: {},
    setAddressValue: {},
  };
  const nextNavigate = {
    label: 'Address Book',
    imgPath: ICON.ADDRESSBOOK_ICON,
    path: MODULE_PATH.STANDARD.SELECT_ADDRESS,
  };
  console.log('provinceCityBarangay:', provinceCityBarangay);
  return (
    <>
      <StickyHeader
        onPrevNavigate={prevNavigate}
        title={pointLocation === 'PickUp' ? 'Sender Details' : 'Recipient Details'}
        onNextNavigate={nextNavigate}
      />

      <div className="content-container v4-addressbook">
        <div className="address-book-card-form">
          <div className="card-box">
            <div className="card-box-label">
              <span className='lbl-large'>Contact Person</span>
              {hasContact && (
                <span className="clear-form" onClick={() => clearContact()}>
                  Clear form
                </span>
              )}
            </div>
            <div className="card-box-fields">
              <FormInput
                value={contactNo}
                onChange={value => handleAddressChange(REF.CONTACT_NO, value)}
                onClick={() => handleClearField(REF.CONTACT_NO)}
                onFocus={() => handleInputFocus(REF.CONTACT_NO)}
                inputFocusName={inputFocus === REF.CONTACT_NO}
                onBlur={() => handleRemoveInputFocus()}
                placeholder="Phone Number"
                prefixOn="PHONE_NO"
                isReqOptional="req"
                validatePhone={isValidPhoneNumber(contactNo)?.isValid}
              />
              <FormInput
                value={fullName}
                onChange={value => handleAddressChange(REF.FULLNAME, value)}
                onClick={() => handleClearField(REF.FULLNAME)}
                onFocus={() => handleInputFocus(REF.FULLNAME)}
                inputFocusName={inputFocus === REF.FULLNAME}
                onBlur={() => handleRemoveInputFocus()}
                placeholder="Contact Name"
                isReqOptional="req"
              />
              <FormInput
                value={emailAddress}
                onChange={value => handleAddressChange(REF.EMAIL, value)}
                onClick={() => handleClearField(REF.EMAIL)}
                onFocus={() => handleInputFocus(REF.EMAIL)}
                inputFocusName={inputFocus === REF.EMAIL}
                onBlur={() => handleRemoveInputFocus()}
                placeholder="Email Address"
              />
              <FormInput
                value={notesToRider}
                onChange={value => handleAddressChange(REF.NOTES_TO_RIDER, value)}
                onClick={() => handleClearField(REF.NOTES_TO_RIDER)}
                onFocus={() => handleInputFocus(REF.NOTES_TO_RIDER)}
                inputFocusName={inputFocus === REF.NOTES_TO_RIDER}
                onBlur={() => handleRemoveInputFocus()}
                placeholder="Note to Rider"
                hasCounter={true}
              />
            </div>
          </div>
          <div className="card-box">
            <div className="card-box-label">
              <span className='lbl-large'>Address</span>
              {hasAddress && (
                <span className="clear-form" onClick={() => clearAddress()}>
                  Clear form
                </span>
              )}
            </div>
            <div className="card-box-fields">
              <FormInput
                value={unitFloorHouse}
                onChange={value => handleAddressChange(REF.UNIT_FLOOR, value)}
                onClick={() => handleClearField(REF.UNIT_FLOOR)}
                onFocus={() => handleInputFocus(REF.UNIT_FLOOR)}
                inputFocusName={inputFocus === REF.UNIT_FLOOR}
                onBlur={() => handleRemoveInputFocus()}
                placeholder="Unit/Floor/House No"
                isReqOptional="req"
                hasCounter={true}
              />
              <FormInput
                value={bldgStreet}
                onChange={value => handleAddressChange(REF.BLG_STREET, value)}
                onClick={() => handleClearField(REF.BLG_STREET)}
                onFocus={() => handleInputFocus(REF.BLG_STREET)}
                inputFocusName={inputFocus === REF.BLG_STREET}
                onBlur={() => handleRemoveInputFocus()}
                placeholder="Building/Street"
                isReqOptional="req"
                hasCounter={true}
              />
              <FormInput
                value={hasInvalidValue(provinceCityBarangay) ? '' : provinceCityBarangay}
                onChange={value => handleAddressChange(REF.PROV_CITY_BRGY, value)}
                onClick={() => handleClearField(REF.PROV_CITY_BRGY)}
                onFocus={() => handleInputFocus(REF.PROV_CITY_BRGY)}
                inputFocusName={inputFocus === REF.PROV_CITY_BRGY}
                onBlur={() => handleRemoveInputFocus()}
                placeholder="Province/City/Barangay"
                isReqOptional="req"
                onSetShow={() => setShowAddressDrawer(true)}
                className="Format-Places"
              />
              <FormInput
                value={postalCode}
                onChange={value => handleAddressChange(REF.POSTAL_CODE, value)}
                onClick={() => handleClearField(REF.POSTAL_CODE)}
                onFocus={() => handleInputFocus(REF.POSTAL_CODE)}
                inputFocusName={inputFocus === REF.POSTAL_CODE}
                onBlur={() => handleRemoveInputFocus()}
                placeholder="Postal Code"
                isReqOptional="req"
              />
              <FormInput
                value={landmark}
                onChange={value => handleAddressChange(REF.LANDMARK, value)}
                onClick={() => handleClearField(REF.LANDMARK)}
                onFocus={() => handleInputFocus(REF.LANDMARK)}
                inputFocusName={inputFocus === REF.LANDMARK}
                onBlur={() => handleRemoveInputFocus()}
                placeholder="Landmark"
                isReqOptional="opt"
              />
            </div>
          </div>
        </div>
        <ProvinceCityBarangay
          provinceOpt={provinceOptions}
          province={province}
          city={city}
          barangay={barangay}
          show={showAddressDrawer}
          onShowDrawer={show => setShowAddressDrawer(show)}
          onSetProvinceCityBrgy={pcb => setProvinceCityBrgy(pcb)}
        />
      </div>
      <FooterBtn
        className={`new-address-book ${!checkForm() ? 'disabled' : ''}`}
        childClass="radius"
        label="Submit Details"
        onClick={() => pushAddress()}
      />
    </>
  );
};

const matchDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setSelectedAddress,
    },
    dispatch
  );

const mapStateToProps = state => ({
  user: state.usersReducer.currentUser,
  selectedAddress: state.addressReducer.selectedAddress,
});

export default connect(mapStateToProps, matchDispatchToProps)(SenderDetails);
