import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation } from 'react-router-dom';
import { debounce } from 'lodash';
import { Button, Skeleton, Space, message } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import IconAddresBook from '../../../../shared/assets/svg/home-booking/icon_addressbook.svg';
import { useNavigate } from 'react-router';
import Maps from './Maps';
import axios from 'axios';

import {
  setDropOff as setOndemandDropOff,
  setPickUp as setOndemandPickUp,
} from '../../../../shared/redux/onDemand/actions';
import {
  setDropOff as setStandardDropOff,
  setPickUp as setStandardPickUp,
} from '../../../../shared/redux/standard/actions';
import { setCourier } from '../../../../shared/redux/onDemand/actions';

import StartPointLocation from '../../../../shared/assets/images/AddressBook/my_location.svg';
import AddressBookDAO from '../../../../shared/utils/dao/AddressBook';
import {
  MAPS_GEOCODE,
  MAPS_API,
  MAPS_API_KEY,
  zeroKm,
} from '../../../../shared/utils/enums/AppConstants';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import {
  isEmpty,
  phoneFormat,
  isValidPhoneNumber,
} from '../../../../shared/utils/helpers/purefunctions';

import { MODULE_PATH } from '../../../../shared/constants/Module';
import StickyHeader from '../../../../shared/components/StickyHeader';
import FormInput from '../../../../shared/components/FormInput';
import { REF } from '../../../../shared/constants/AddressBook';
import { memo } from 'react';
import FooterBtn from '../../../../shared/components/FooterBtn';
import arrowRight from '../../../../shared/assets/images/OndemandDelivery/arrow_right.svg';

const PinLocation = ({ view, user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [address, setAddress] = React.useState('');
  const [pinLocation, setPinLocation] = useState(null);
  const [latAndLng, setLatandLng] = useState({});
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [setMarkerMove, onSetMarkerMove] = useState(false);
  const [isFillUp, setIsFillUp] = useState(false);
  const [inputFocus, setInputFocus] = useState(null);
  const [isClicked, setIsClicked] = useState(false);
  const [hideForm, onSetHideForm] = useState(false);
  const [mapHeight, setMapHeight] = useState('50%');
  const [markerAddress, onSetMarkerAddress] = useState({});
  const [notesToRider, setNotesToRider] = useState({ notes: '' });
  const [senderDetails, setSenderDetails] = useState({
    id: '',
    contactNo: '',
    fullName: '',
    emailAddress: '',
    unitFloorHouse: '',
    bldgStreet: '',
    province: '',
    city: '',
    barangay: '',
    postalCode: '',
    landmark: '',
  });

  const onDemandProps = useSelector(state => state.onDemandReducer);
  const standardProps = useSelector(state => state.standardReducer);

  const { formTab } = useSelector(state => state.homeReducer);
  const deliveryType = 'onDemand-view';
  const isOndemand = formTab === deliveryType;

  const deliveryProps = isOndemand ? onDemandProps : standardProps;

  const { pickup = {}, dropOff = {}, pointLocation, location } = deliveryProps;

  const locate = useLocation();
  const locationReference = useSelector(state => state.onDemandReducer.locationReference);
  const selectedAddress = useSelector(state => state.addressReducer.selectedAddress);
  const ondemand = useSelector(state => state.onDemandReducer);
  const selectedAddressDetails = location ? location.split(',') : '';
  const hasAddress = pinLocation?.formatted_address;
  const { contactNo, fullName, unitFloorHouse } = senderDetails;

  const isFromCurrentAddress = locate?.state?.isFromCurrentAddress;

  let cityLocation = '';
  let provinceLocation = '';

  useEffect(() => {
    if (!isFromCurrentAddress && !isEmpty(selectedAddress) && locationReference) {
      setSenderDetails({
        ...selectedAddress,
        fullName: selectedAddress.fullName,
        unitFloorHouse: selectedAddress.unitFloorHouse,
        contactNo: selectedAddress.contactNo,
      });
    }

    if (isEmpty(selectedAddress) && pointLocation === 'dropOff') {
      setSenderDetails({});
    }
  }, []);

  useEffect(() => {
    let address = '';

    if (pointLocation === 'PickUp' && !isEmpty(pickup?.senderDetails)) {
      const {
        unitFloorHouse = '',
        bldgStreet = '',
        barangay = '',
        city = '',
        province = '',
      } = pickup.senderDetails;
      address = `${unitFloorHouse} ${bldgStreet} ${barangay} ${city} ${province}`;
    }

    if (pointLocation === 'DropOff' && !isEmpty(dropOff?.recipientDetails)) {
      const {
        unitFloorHouse = '',
        bldgStreet = '',
        barangay = '',
        city = '',
        province = '',
      } = dropOff.recipientDetails;
      address = `${unitFloorHouse} ${bldgStreet} ${barangay} ${city} ${province}`;
    }
    onSetGeocodeByAddress(location);
    return () => {};
  }, []);

  useEffect(() => {
    const { fullName, unitFloorHouse, contactNo } = senderDetails;
    setIsFillUp([contactNo, unitFloorHouse, fullName].every(value => !isEmpty(value)));
  }, [senderDetails]);

  useEffect(() => {
    const { pickUp, dropOff } = ondemand;
    const details = pointLocation === 'PickUp' ? pickUp?.senderDetails : dropOff?.recipientDetails;

    if (!isEmpty(details) && !isFromCurrentAddress) {
      const { address, contactNo, fullName, unitFloorHouse } = details;

      setSenderDetails({
        address: address || '',
        contactNo: contactNo || '',
        fullName: fullName || '',
        unitFloorHouse: unitFloorHouse || '',
      });
    }
  }, []);

  const navigatePinLocation = () => {
    if (pointLocation === 'PickUp') {
      cityLocation = pickup?.city || '';
      provinceLocation = pickup?.province || '';
    }

    if (pointLocation === 'DropOff') {
      cityLocation = dropOff?.city || '';
      provinceLocation = dropOff?.province || '';
    }

    const { contactNo, fullName, emailAddress, unitFloorHouse, postalCode } = senderDetails;

    // if (!isOndemand && isEmpty(postalCode)) {
    //   return showNotif('error', 'Postal code is required.');
    // }

    if (contactNo) {
      const validateContactNumber = /^9\d{9}$/;

      if (!validateContactNumber.test(contactNo.replace(/ /g, '')))
        return showNotif('error', 'Please enter a valid mobile number.');
    }

    if (emailAddress) {
      const validateEmail = String(emailAddress)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );

      if (!validateEmail) return showNotif('error', 'Invalid email format. Please try again.');
    }

    const mobileNo = contactNo.replace(/ /g, '');

    if (pointLocation === 'PickUp') {
      let setPickUpAdd = {
        ...pickup,
        pickupAddress: markerAddress?.pickupAddress || location,
        address: markerAddress?.address || location,
        notesToRider,
        latitude: markerAddress?.latitude || latAndLng.lat,
        longitude: markerAddress?.longitude || latAndLng.lng,
        senderDetails: {
          ...senderDetails,
          contactNo: mobileNo[0] === '9' ? `0${mobileNo}` : mobileNo || user.gcashNumber,
          fullName: isEmpty(senderDetails.fullName)
            ? `${user.firstName} ${user.lastName}`
            : senderDetails.fullName,
          address:
            markerAddress?.address || (locationReference ? location : selectedAddress.address),
          province: markerAddress?.province || pickup.province,
          city: markerAddress?.city || pickup.city,
          barangay: pickup.barangay || 'brgy',
          emailAddress: senderDetails?.emailAddress || '',
        },
      };
      if (isOndemand) {
        dispatch(setOndemandPickUp(setPickUpAdd));
        if (setPickUpAdd.senderDetails.address !== onDemandProps?.pickUp?.address) {
          dispatch(setCourier({}));
        }
      } else {
        dispatch(setStandardPickUp(setPickUpAdd));
        if (setPickUpAdd.senderDetails.address !== standardProps?.pickUp?.address) {
          dispatch(setCourier({}));
        }
      }
    }

    if (pointLocation === 'DropOff') {
      let setDropOffAdd = {
        ...dropOff,
        deliveryAddress: markerAddress?.pickupAddress || location,
        address: markerAddress?.address || location,
        addressType: selectedAddressDetails[0],
        notesToRider,
        latitude: markerAddress?.latitude || latAndLng.lat,
        longitude: markerAddress?.longitude || latAndLng.lng,
        recipientDetails: {
          ...senderDetails,
          contactNo: mobileNo[0] === '9' ? `0${mobileNo}` : mobileNo || user.gcashNumber,
          fullName: isEmpty(senderDetails.fullName)
            ? `${user.firstName} ${user.lastName}`
            : senderDetails.fullName,
          address:
            markerAddress?.address || (locationReference ? location : selectedAddress.address),
          province: markerAddress?.province || dropOff.province,
          city: markerAddress?.city || dropOff.city,
          barangay: dropOff.barangay || 'brgy',
          emailAddress: senderDetails?.emailAddress || '',
        },
      };
      if (isOndemand) {
        dispatch(setOndemandDropOff(setDropOffAdd));
        if (setDropOffAdd.recipientDetails.address !== onDemandProps?.dropOff?.address) {
          dispatch(setCourier({}));
        }
      } else {
        dispatch(setStandardDropOff(setDropOffAdd));
        if (setDropOffAdd.recipientDetails.address !== standardProps?.dropOff?.address) {
          dispatch(setCourier({}));
        }
      }
    }

    if (isSubscribed) {
      const addressBookDao = new AddressBookDAO();

      const address = {
        location,
        unitFloorHouse,
        fullName,
        contactNo,
        cityLocation,
        provinceLocation,
        addressType: selectedAddressDetails[0],
        latitude: latAndLng.lat,
        longitude: latAndLng.lng,
      };
      addressBookDao.saveLocation(user.id, address);
    }

    navigate(MODULE_PATH.HOME);
  };

  const showNotif = debounce(async (type, msg) => {
    message[type](msg);
  }, 500);

  const onSetGeocodeByAddress = debounce(address => {
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        onSetPinLocation({
          lat: latLng.lat,
          lng: latLng.lng,
        });
        setLatandLng({ lat: latLng.lat, lng: latLng.lng });
      })
      .catch(err => {
        if (err === 'ZERO_RESULTS') {
          message.error('Address not found. Please manually pin your location instead.', 1.5);
        } else {
          // message.error(err);
        }
      });
  }, 1000);

  const onSetPinLocation = debounce(async data => {
    try {
      const res = await axios.get(
        `${MAPS_GEOCODE}?latlng=${data.lat},${data.lng}&key=${MAPS_API_KEY}`
      );
      if (res.data.results[0]) {
        const resData = res.data.results[0];
        const addressComponents = resData?.address_components;
        setPinLocation(resData);

        if (!addressComponents) return;

        let city = '';
        let province = '';
        addressComponents.forEach(component => {
          if (component.types.includes('locality')) {
            city = component.long_name;
          } else if (component.types.includes('administrative_area_level_1')) {
            province = component.long_name;
          }
        });

        onSetMarkerAddress({
          unitFloorHouse: addressComponents[0].long_name,
          pickupAddress: resData?.formatted_address,
          address: resData?.formatted_address,
          city: city,
          province: province,
          latitude: data.lat,
          longitude: data.lng,
        });
      } else {
        message.error("Can't locate address. ", 1.5);
      }
    } catch (err) {
      // message.error(err);
    }
  }, 1000);

  const handleInputFocus = fieldName => {
    !isClicked && setInputFocus(fieldName);
  };

  const handleMarkerDragEnd = e => {
    onSetMarkerMove(true);
    onSetPinLocation({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  };

  const handleRemoveInputFocus = () => {
    if (!isClicked && inputFocus === 'contactNo' && !isEmpty(senderDetails?.contactNo)) {
      let newValue = senderDetails.contactNo.replace(/ /g, '');
      const validatePhone = isValidPhoneNumber(senderDetails.contactNo);
      if (validatePhone.isValid) {
        const input = validatePhone.value;
        var zip = input.substring(0, 3);
        var middle = input.substring(3, 6);
        var last = input.substring(6, 10);
        newValue = `${zip} ${middle} ${last}`;
      }
      setSenderDetails({ ...senderDetails, contactNo: newValue });
    }

    setInputFocus(null);
  };

  const handleCheckBox = event => {
    setIsSubscribed(current => !current);
  };

  const handleClearField = field => {
    setIsClicked(true);
    setSenderDetails({
      ...senderDetails,
      [field]: '',
    });
    setIsClicked(false);
  };

  const handleNotesClear = field => {
    setNotesToRider({ ...notesToRider, [field]: '' });
  };

  const handleFormChange = useCallback(
    (field, value) => {
      setSenderDetails({ ...senderDetails, [field]: value });
    },
    [senderDetails]
  );

  const handleChangeNote = (field, value) => {
    setNotesToRider({ ...notesToRider, [field]: value });
  };

  const ProceedButton = ({ onClick }) => {
    const buttonLabel =
      pointLocation === 'PickUp' ? 'Confirm Pickup Location' : 'Confirm Drop Off Location';
    return (
      <FooterBtn
        className="sender-details-btn"
        childClass="radius"
        label={buttonLabel}
        onClick={onClick}
      />
    );
  };

  const handleChange = value => {
    setAddress(value);
  };

  const handleSelect = value => {
    setAddress(value);
  };

  const preventHandler = e => {
    e.stopPropagation();
  };

  const addressValue = hasAddress ? (
    location || pinLocation?.formatted_address
  ) : (
    <Space style={{ display: 'grid' }}>
      <Skeleton.Input block={true} active={true} size={'small'} />
      <Skeleton.Input block={true} active={true} size={'small'} />
    </Space>
  );

  const AddressDetails = () => {
    if (!setMarkerMove && locate?.state?.showAutoComplete) {
      return (
        <div>
          <PlacesAutocomplete
            value={addressValue}
            onChange={handleChange}
            onSelect={handleSelect}
            className="address-data"
          >
            {({ suggestions, getSuggestionItemProps, loading }) => (
              <div>
                <span className={`address-info ${hasAddress ? '' : 'address-loader'} cntnt-caption`}>
                  {addressValue}
                </span>
                <div>
                  {loading && <div>Loading...</div>}
                  {suggestions.map(suggestion => {
                    const style = suggestion.active
                      ? { backgroundColor: '#a83232', cursor: 'pointer' }
                      : { backgroundColor: '#ffffff', cursor: 'pointer' };

                    return (
                      <div {...getSuggestionItemProps(suggestion, { style })}>
                        {suggestion.description}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        </div>
      );
    }

    return (
      <div className="address-data">
        <span className={`address-info ${hasAddress ? '' : 'address-loader'} cntnt-caption`}>
          {hasAddress ? (
            markerAddress?.address || location || pinLocation?.formatted_address
          ) : (
            <Space style={{ display: 'grid' }}>
              <Skeleton.Input
                block={true}
                active={true}
                size={'small'}
                style={{ width: '380px', height: '15px' }}
              />
              <Skeleton.Input
                block={true}
                active={true}
                size={'small'}
                style={{ width: '380px', height: '15px' }}
              />
            </Space>
          )}
        </span>
      </div>
    );
  };

  const prevNavigate = {
    label: pointLocation === 'PickUp' ? 'Pickup location' : 'Drop-off location',
    imgPath: '',
    path: locate?.state?.fromHome ? MODULE_PATH.HOME : (isOndemand
      ? MODULE_PATH.ADDRESS.ON_DEMAND.SELECT_ADDRESS
      : MODULE_PATH.ADDRESS.STANDARD.SELECT_ADDRESS),
    state: locate.state
  };

  const dynamicHeight = hideForm ? 'calc(100vh - 130px)' : 'calc(100vh - 465px)';

  return (
    <div className="pin-location-container">
      <div className="addressbook-container">
        <StickyHeader
          title={pointLocation === 'PickUp' ? 'Pickup location' : 'Drop-off location'}
          onPrevNavigate={prevNavigate}
        />
        <div className="address-details">
          <img src={StartPointLocation} alt="point location" />
          <AddressDetails />
        </div>
      </div>

      {hasAddress && (
        <>
          <Maps
            googleMapURL={`${MAPS_API}?key=${MAPS_API_KEY}`}
            coordinates={pinLocation?.geometry?.location || zeroKm}
            onSetPinLocation={(location, latlong) => onSetPinLocation(location)}
            onMarkerDragEnd={handleMarkerDragEnd}
            containerElement={
              <div
                onClick={() => onSetHideForm(!hideForm)}
                style={{ height: dynamicHeight }}
                className="map-container"
              ></div>
            }
            mapElement={<div style={{ height: `100%`, marginTop: '-110px' }} />}
            loadingElement={<div style={{ height: `50%` }} />}
          />

          <div className={`sender-details-fields ${hideForm ? 'hide-form' : ''}`}>
            <div className="sender-details-form">
              <span className="title">
                <span className='lbl-large'>{pointLocation === 'PickUp' ? 'Pickup Details' : 'Drop-off Details'}</span>
                <span className="arrow-right" onClick={() => onSetHideForm(!hideForm)}>
                  <img src={arrowRight} alt="arrow-right" />
                </span>
              </span>
              <FormInput
                value={unitFloorHouse}
                onChange={value => handleFormChange(REF.UNIT_FLOOR, value)}
                onClick={() => handleClearField(REF.UNIT_FLOOR)}
                onFocus={() => handleInputFocus(REF.UNIT_FLOOR)}
                inputFocusName={inputFocus === REF.UNIT_FLOOR}
                onBlur={() => handleRemoveInputFocus()}
                placeholder="Floor and unit no"
                hasCounter={true}
              />

              <FormInput
                value={fullName}
                onChange={value => handleFormChange(REF.FULLNAME, value)}
                onClick={() => handleClearField(REF.FULLNAME)}
                onFocus={() => handleInputFocus(REF.FULLNAME)}
                inputFocusName={inputFocus === REF.FULLNAME}
                onBlur={() => handleRemoveInputFocus()}
                placeholder="Contact Name"
              />
              <FormInput
                value={contactNo}
                onChange={value => handleFormChange(REF.CONTACT_NO, value)}
                onClick={() => handleClearField(REF.CONTACT_NO)}
                onFocus={() => handleInputFocus(REF.CONTACT_NO)}
                inputFocusName={inputFocus === REF.CONTACT_NO}
                onBlur={() => handleRemoveInputFocus()}
                placeholder="Phone Number"
                prefixOn="PHONE_NO"
              />
              <FormInput
                value={notesToRider.notes}
                onChange={value => handleChangeNote('notes', value)}
                onClick={() => handleNotesClear('notes')}
                onFocus={() => handleInputFocus('notes')}
                inputFocusName={inputFocus === 'notes'}
                onBlur={() => handleRemoveInputFocus()}
                placeholder="Notes to Rider"
              />

              <div className="forms-checkbox">
                <input
                  type="checkbox"
                  value={isSubscribed}
                  onChange={handleCheckBox}
                  id="subscribe"
                  name="subscribe"
                  className={isSubscribed ? 'check-box-active' : 'checkbox-inactive'}
                />
                <div className="label">
                  <span className={`${isSubscribed ? 'active' : 'inactive'} lbl-small`}>Save this place</span>
                  <span className='cntnt-caption'>
                    Save this location to your <img src={IconAddresBook} alt="address book" />
                    address book for future orders.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <ProceedButton onClick={navigatePinLocation} />
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  view: state.homeReducer.view,
  user: state.usersReducer.currentUser,
});

export default connect(mapStateToProps, null)(memo(PinLocation));
