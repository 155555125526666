import {
  collection,
  onSnapshot,
  query,
  where,
  updateDoc,
  doc,
  getDocs,
  orderBy,
  limit,
} from 'firebase/firestore';
import { firestoreDb } from '../helpers/firebaseUtils';
import axios from 'axios';
import { message } from 'antd';
import { DEPLOYMENT } from '../enums/AppConstants';

import { isEmpty } from '../../utils/helpers/purefunctions';
import store from '../../redux/store';

class Orders {
  constructor() {
    this.pendingPath = 'GCASH/POST/PENDING';
    this.donePath = 'GCASH/POST/DONE';
    this.orderTrailPath = `GCASH/POST_TRAIL`;
    this.API = DEPLOYMENT.PARCELS_API;
  }

  async getOrders(path, createdBy, orderId, opt, statuses = 'CANCELLED', forRefund, method = '', fnCallback) {
    try {
      let dynamicWhereClauses = [
        { field: 'createdBy', operator: '==', value: createdBy },
        { field: 'status', operator: opt, value: statuses },
      ];

      const collectionRef = collection(firestoreDb, path);

      let dynamicQuery = query(collectionRef);

      dynamicWhereClauses.forEach(whereClause => {
        const { field, operator, value } = whereClause;
        dynamicQuery = query(dynamicQuery, where(field, operator, value));
      });

      if (forRefund !== null) {
        dynamicQuery = query(dynamicQuery, where('gcash.refund.success', opt, forRefund));
      } else {
        dynamicQuery = query(dynamicQuery, orderBy('status'));
      }

      dynamicQuery = query(dynamicQuery, orderBy('createdAt', 'desc'));

      if (forRefund == false) {
        dynamicQuery = query(dynamicQuery, limit(10));
      }

      this.unsubscibePendingOrders = onSnapshot(dynamicQuery, querySnapshot => {
        const obj = [];
        let selectedOrder = {};

        let targetOrderId = orderId;
        if (isEmpty(orderId)) {
          const state = store.getState();
          targetOrderId = state.homeReducer.targetOrder;
        }

        querySnapshot.docs.forEach(doc => {
          if (targetOrderId && doc.data().postId === targetOrderId.postId) {
            selectedOrder = doc.data();
          }
          obj.push(doc.data());
        });

        fnCallback(obj, selectedOrder);
      });

      console.log('method:', method, obj.length)
      return this.unsubscibePendingOrders;
    } catch (err) {
      // console.log(`getOrdersPending: ${(err, err.message)}`);
    }
  }
  async getPendingOrdersByOrderId(createdBy, orderId) {
    const collectionRef = collection(firestoreDb, this.pendingPath);
    let dynamicQuery = query(collectionRef);
    const status = [
      'REJECTED',
      'MPREJECTED',
      'FAILED_PICKUP',
      'FAILED_DELIVERY',
      'FAILED_RETURN',
      'CANCELLED',
    ];
    dynamicQuery = query(dynamicQuery, where('createdBy', '==', createdBy));
    dynamicQuery = query(dynamicQuery, where('refNo', '==', orderId));
    dynamicQuery = query(dynamicQuery, where('status', 'not-in', status));

    const querySnapshot = await getDocs(dynamicQuery);

    const data = querySnapshot.docs.map(doc => doc.data());
    return data;
  }

  async getPendingOrders(createdBy, orderId, fnCallback) {
    const status = [
      'REJECTED',
      'MPREJECTED',
      'FAILED_PICKUP',
      'FAILED_DELIVERY',
      'FAILED_RETURN',
      'CANCELLED',
    ];
    this.getOrders(this.pendingPath, createdBy, orderId, 'not-in', status, null, 'getPendingOrders', fnCallback);
  }

  async getForRefundOrders(createdBy, orderId, fnCallback) {
    this.getOrders(this.pendingPath, createdBy, orderId, '==', undefined, true, '', fnCallback);
  }

  async getCancelledOrders(createdBy, orderId, fnCallback) {
    const status = [
      'REJECTED',
      'MPREJECTED',
      'FAILED_PICKUP',
      'FAILED_DELIVERY',
      'FAILED_RETURN',
      'CANCELLED',
    ];
    this.getOrders(this.pendingPath, createdBy, orderId, 'in', status, null, '', fnCallback);
  }

  async getDoneOrders(createdBy, orderId, offset, fnCallback) {
    try {
      const response = await axios({
        method: 'GET',
        url: `${this.API}/v1/getCompletedPosts`,
        headers: {
          Accept: 'application/json',
          Authorization: 'BEARER 02c5b2498d32e983b943c6d7260b1008',
        },
        params: {
          userId: createdBy,
          offset, // false last 10, true 2nd set of last 10
        },
      });
      const result = response.data.result;
      const selectedOrder = result.find(data => data.postId === orderId.postId);
      fnCallback(result, selectedOrder);
    } catch (err) {
      return err.response;
    }
  }

  offFetchPendingOrders() {
    this.unsubscibePendingOrders && this.unsubscibePendingOrders();
  }

  offFetchNotPendingOrders() {
    this.unsubscibeNotPendingOrders && this.unsubscibeNotPendingOrders();
  }

  updateOrder(id, data) {
    return updateDoc(doc(firestoreDb, this.pendingPath, id), data);
  }

  /*get order trail sort by latest timestamp and has remarks field*/
  async getOrderTrailOnce(postId) {
    const queryRef = query(
      collection(firestoreDb, `${this.orderTrailPath}/${postId}`),
      where('remarks', '!=', null)
    );
    const querySnapshot = await getDocs(queryRef);

    const orderTrail = [];
    querySnapshot.forEach(snapshot => {
      const data = snapshot.data();
      orderTrail.push({
        id: snapshot.id,
        ...data,
      });
    });

    return orderTrail.sort((a, b) => b.timeStamp - a.timeStamp);
  }

  async subscribeToOrderTrailChanges(postId, callback) {
    const queryRef = query(
      collection(firestoreDb, `${this.orderTrailPath}/${postId}`),
      where('remarks', '!=', null)
    );

    const unsubscribe = onSnapshot(queryRef, snapshot => {
      const orderTrail = [];
      snapshot.forEach(doc => {
        const data = doc.data();
        orderTrail.push({
          id: doc.id,
          ...data,
        });
      });

      orderTrail.sort((a, b) => b.timeStamp - a.timeStamp);
      callback(orderTrail);
    });

    return unsubscribe;
  }
}

export default Orders;
